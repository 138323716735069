import { useContext, createContext } from "react";

export const AppContext = createContext(null);

// export default function Provider(props) {
//   return <AppContext.Provider isAuthenticated={false}>{props.children}</AppContext.Provider>;
// }

export function useAppContext() {
  return useContext(AppContext);
}