import React from "react";
import { Route, Switch } from "react-router-dom";
import Signup from "./comps/Signup";
import Login from "./comps/Login";
import NotFound from "./comps/NotFound";
import App from "./App";
import Home from "./comps/Home";

import AppliedRoute from "./AppliedRoute";

export default function Routes({ appProps }) {
  return (
    <Switch>
      <Route exact path="/">
        <Home appProps={appProps}/>
      </Route>
      <Route exact path="/home">
        <Home appProps={appProps}/>
      </Route>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/signup">
        <Signup />
      </Route>
      
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}