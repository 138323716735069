import React from 'react';
import { Storage } from 'aws-amplify' 
import { S3Image } from 'aws-amplify-react';
import { motion } from 'framer-motion';
import { Button, Image } from 'react-bootstrap';

class ShortList extends React.Component {
  constructor(props){
    super(props);
    this.collectImageId = this.collectImageId.bind(this);
    // this.state.files= [this.props.images];
    console.log(props)
  }
  state = {
    // files: [this.props.images],
    files:[],
    shortlistedImages : []
  }
  async shouldComponentUpdate(){
    // this.setState({files:this.props.images});
  }
  async componentDidUpdate(previousProps){
    if(previousProps.images !== this.props.images){
      let imageKeys = [];
      if(this.props.images)
       {
          this.props.images.map(each =>{
          imageKeys.push(each.key);
        });
        this.setState({files:imageKeys});
       }
    }
  }
  async componentDidMount() {
    // check the render image list
    console.log('found below images\n'+this.state.images);
  }

  collectImageId(params) {
    this.props.removeShortListed({key:params});
  };

  render() {
    return (
      <div >

          <div className="row">

        {
          this.state.files.map((f, i) => (
          <div className="col-sm-4">
            <div className = "card" style={{ width: "18rem"}}>

                <S3Image className ="card-img-top" identityId='ap-south-1:e4f7351e-2de2-48d4-b53c-5e45399a4dac' imgKey={f}
                   alt="uploaded pic"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 1 }}
                />

                <div className="card-body">
                   {!(this.props.shortListedImages && (this.props.shortListedImages.reduce((accumulator, currentValue) => accumulator + currentValue.key+',','').split(',').includes(f))) ?<Button onClick={this.collectImageId.bind(this,f)}>Remove</Button>:''}
                </div>
            </div>
          </div>
          ))
        }
        </div>
      </div>
    )

}
}

export default ShortList;