import React, { useState, useEffect } from "react";
// import logo from './logo.svg';
import './App.css';
import Routes from "./Routes";

import { Nav, Navbar, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import Provider, { AppContext }  from "./libs/contextLib";
import { Auth,Storage } from "aws-amplify";
import { Link, useHistory } from "react-router-dom";

import { onError } from "./libs/errorLib";

function Main() {
  const history = useHistory();
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [allImages, setAllImages] = useState([]);
  
  useEffect(() => {
    onLoad();
  }, []);
  
  async function onLoad() {
    // try {
      setTimeout(async()=>{
        try{
          const session = await Auth.currentSession();
          let accessToken = session.getAccessToken()
          // resolve(accessToken);
          if(accessToken){
            userHasAuthenticated(true);
          }
        }catch(error){
          userHasAuthenticated(false);
        }
        // resolve()
      },1000);
      // userHasAuthenticated(true);
    // }
      
    // catch(e) {
    //   console.log(JSON.parse(e));
    // }
  
    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();
  
    userHasAuthenticated(false);
  
    history.push("/login");
  }

  return (
    !isAuthenticating &&
    <div className="App container ">
      <Navbar fluid collapseOnSelect navTransition>
        <Navbar.Header>
          <Navbar.Brand>
            <Link to="/">Saptarshi Weds Sayani</Link>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav pullRight>
            {isAuthenticated
              ? <NavItem onClick={handleLogout}>Logout</NavItem>
              : <>
                  <LinkContainer to="/signup">
                    <NavItem>Signup</NavItem>
                  </LinkContainer>
                  <LinkContainer to="/login">
                    <NavItem>Login</NavItem>
                  </LinkContainer>
                </>
            }
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <AppContext.Provider value={{isAuthenticated, userHasAuthenticated}}>
        <Routes />
      </AppContext.Provider>
    </div>
  );
}
export default Main;
