import React, { useState } from "react";
import Auth,  { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { useHistory } from "react-router-dom";
import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import "./Login.css";

export default function Login() {
  const history = useHistory();
  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: ""
  });

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleFacebookSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      await Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Facebook});
      setTimeout(async()=>{
        try{
          const session = await Auth.currentSession();
          let accessToken = session.getAccessToken()
          // resolve(accessToken);
        }catch(error){
          // reject(error)
        }
        // resolve()
      },1000);
      userHasAuthenticated(true);
      history.push("/");
    }catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }
  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.signIn(fields.email, fields.password);
      userHasAuthenticated(true);
      history.push("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  return (
    <div>
      
      
      <div className="Login">
        <form onSubmit={handleSubmit}>
          <FormGroup controlId="email" bsSize="large">
            <ControlLabel>Email</ControlLabel>
            <FormControl
              autoFocus
              type="email"
              value={fields.email}
              onChange={handleFieldChange}
            />
          </FormGroup>
          <FormGroup controlId="password" bsSize="large">
            <ControlLabel>Password</ControlLabel>
            <FormControl
              type="password"
              value={fields.password}
              onChange={handleFieldChange}
            />
          </FormGroup>
          <LoaderButton
            block
            type="submit"
            bsSize="large"
            isLoading={isLoading}
            disabled={!validateForm()}
          >
            Login with email and password
          </LoaderButton>
        </form>
      </div>
      <div className="Login">
        <Button onClick={handleFacebookSubmit}> Login with Facebook </Button>
      </div>
  </div>
  );
}