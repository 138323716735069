import React from "react";
import styled from "styled-components";

const Welcome = () => {
  return (
    <Container>
      <h1>
        Please SignUp / Login 
      </h1>
    </Container>
  )
};

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 65px;
    font-weight: 900;
    color: red;

    @media (max-width: 900px) {
      display: none;
    }
  }
`;

export default Welcome;