import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Main from './main';
import Amplify from 'aws-amplify'
import {AWS} from './configs/config';
import { BrowserRouter as Router } from 'react-router-dom';

//configure amplify
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: AWS.cognito.REGION,
    userPoolId: AWS.cognito.USER_POOL_ID,
    identityPoolId: AWS.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: AWS.cognito.APP_CLIENT_ID,
    // TODO update this part after deploying the cognito
    oauth: {
      domain: 'sapsaymarriage.auth.ap-south-1.amazoncognito.com',
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: 'https://mymarriage.codegigs.app',
      redirectSignOut: 'https://mymarriage.codegigs.app',
      responseType: 'token' // or 'token', note that REFRESH token will only be generated when the responseType is code
  }
  },
  Storage: {
    AWSS3:{
    region: AWS.s3.REGION,
    bucket: AWS.s3.BUCKET,
    identityPoolId: AWS.cognito.IDENTITY_POOL_ID
    }
  },
  API: {
    endpoints: [
      {
        name: "mymarriage-backend",
        endpoint: AWS.apiGateway.URL,
        region: AWS.apiGateway.REGION
      },
    ]
  }
});

ReactDOM.render(
  <Router>
    <Main />
  </Router>,
  document.getElementById('root')
);
