import {cognito} from './cognito';

export const MAX_ATTACHMENT_SIZE= 50000000000;

export const AWS = {
    s3: {
      REGION: "ap-south-1",
      // BUCKET: "saysapmarriage",
      BUCKET: "node-resizer-dev-attachmentsbucket-kqy786az0r7u"
    },
    apiGateway: {
      REGION: "ap-south-1",
      URL: "https://33cdrut9pf.execute-api.ap-south-1.amazonaws.com/dev"
    },
    cognito: cognito
  }
