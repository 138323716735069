import React, { useState, useEffect, ScrollableComponent } from "react";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import "./Home.css";
import Images from "./Images";
import { Tabs, Tab } from "react-bootstrap";
import { Auth, Storage } from "aws-amplify";
import ShortList from "./Shortlists";
import { API } from "aws-amplify";
import styled from "styled-components";

import Welcome from "./welcome";
import bgImg from "../assets/bg.png";

export default function Home(props) {
  const [notes, setNotes] = useState([]);
  
  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [imageNextIndex, setImageNextIndex] = useState(0);
  const [renderImages, setRenderImages] = useState([]);

  const [shortListedImages, setShortListedImages] = useState([]);

  const [key, setKey] = useState("all");

  const [userEmail, setUserEmail] = useState("");

  async function isLoggedInOnCognito(){
      try {
            await Auth.currentAuthenticatedUser()
            return true
          }
      catch(e)
      {
        return false
      }
    }

  async function updateShortListedImages(imgSrc) {
    setShortListedImages([...shortListedImages, imgSrc]);
    //save to dynamo
    await saveShortListedImage(imgSrc.key);
  }

  async function removeShortListedImage(params) {
    if (
      (await Auth.currentSession().session.idToken.payload.email) !==
      "saptarshi.misra@gmail.com"
    )
      return;
    //shlimgdel API.del
    let toDel = shortListedImages.filter((item) => item.key === params.key);
    let target;
    if (toDel) {
      target = toDel[0].id;
    }
    const saveResponse = await API.del("mymarriage-backend", "/shlimgdel", {
      body: {
        imgId: target,
      },
    });
    setShortListedImages([
      ...shortListedImages.filter((item) => item.key !== params.key),
    ]);
  }

  async function findAllSavedImages() {
    const response = await API.get("mymarriage-backend", "/shlimgget");
    //    console.log('Saved images \n'+response);
    //    if(response.images)console.log('Saved imageIds \n'+response.images.map(x => x.imgId).join());
    setShortListedImages(
      response.images.map((x) => {
        return { key: x.imgId, id: x.id };
      })
    );
  }
  async function saveShortListedImage(imgId) {
    if (
      (await Auth.currentSession().session.idToken.payload.email) !==
      "saptarshi.misra@gmail.com"
    )
      return;
    const saveResponse = await API.post("mymarriage-backend", "/shlimgsave", {
      body: {
        user: "Saptarshi",
        imgId: imgId,
      },
    });
    setShortListedImages([
      ...shortListedImages,
      { key: imgId, id: saveResponse.saveId },
    ]);
  }

  async function findAllImages() {
    //fetch the list of files
    Storage.configure({
      customPrefix: {
        public: "",
        protected: "",
        private: "",
      },
    });
    setTimeout(async()=>{
      try{
        const session = await Auth.currentSession();
        setUserEmail(session.idToken.payload.email);  
      }catch(error){
      }
    },2000);
    // let session = await Auth.currentSession();
    // setUserEmail(session.idToken.payload.email);
    //    console.log('Session \n'+JSON.stringify());
    let filesS3 = await Storage.list("resized/300x300");
    // console.log('File names\n'+ JSON.stringify(filesS3))
    let allImages = [];
    filesS3.map((file) => {
      allImages.push(file);
    });
    let toRender = [];
    for (let i = 0; i < 12; i++) {
      toRender.push(allImages[i]);
    }

    setImageNextIndex(imageNextIndex + 12);
    setRenderImages(toRender);
    setImages(allImages);
  }
  async function findNextImages() {
    let toRenderNext = [];
    for (let i = imageNextIndex; i < imageNextIndex + 12; i++) {
      toRenderNext.push(images[i]);
    }
    setImageNextIndex(imageNextIndex + 12);
    setRenderImages(toRenderNext);
  }
  async function findPreviousImages() {
    let toRenderPrev = [];
    for (let i = imageNextIndex - 13; i >= imageNextIndex - 24; i--) {
      toRenderPrev.push(images[i]);
    }
    setImageNextIndex(imageNextIndex - 12);
    setRenderImages(toRenderPrev.reverse());
  }
  async function refreshImages() {
    await findAllSavedImages();
  }

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated ) {

        return;
      }
      try {
        await findAllImages();
        await findAllSavedImages();
      } catch (e) {
        onError(e);
      }
      setIsLoading(false);
    }

    onLoad();
    // const authVa = (isAuthenticated);
    // userHasAuthenticated(isAuthenticated);
    // console.log(`Auth is  ${authVa}`);
  }, [isAuthenticated]);

  function renderLander() {
    return (
      <div>
        <Container>
          <Wrapper>
            <Welcome />
          </Wrapper>
        </Container>
      </div>
    );
  }
  const Container = styled.div`
    background: #eefcff;
    width: 100%;
    height: 100vh;
  `;
  const Wrapper = styled.div`
    background-image: url(${bgImg});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: flex;
  `;
  async function handleScroll(e) {
    let header = document.getElementById("myHeader");
    var sticky = header.offsetTop;
    if (window.pageYOffset > sticky) {
      header.classList.add("sticky");
    } else {
      header.classList.remove("sticky");
    }
  }

  function renderGallery() {
    return (
      <div>
        <div className="header" id="myHeader" onScroll={handleScroll}>
          {/* <PageHeader>Images</PageHeader> */}
          <button
            className="float-{sm,md,lg,xl}-right"
            onClick={findNextImages}
          >
            Next{" "}
          </button>
          {imageNextIndex > 12 ? (
            <button className="pull-left" onClick={findPreviousImages}>
              Previous
            </button>
          ) : (
            ""
          )}
        </div>

        <Images
          images={renderImages}
          shortListImages={updateShortListedImages}
        />
      </div>
    );
  }

  function ControlledTabs() {
    return (
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Tab eventKey="all" title={`${images.length} Pictures`}>
          <div>
            <div className="header" id="myHeader" onScroll={handleScroll}>
              {/* <PageHeader>Images</PageHeader> */}
              <button
                className="float-{sm,md,lg,xl}-right"
                onClick={findNextImages}
              >
                Next{" "}
              </button>
              {imageNextIndex > 12 ? (
                <button className="pull-left" onClick={findPreviousImages}>
                  Previous
                </button>
              ) : (
                ""
              )}
            </div>

            <Images
              images={renderImages}
              updateShortListedImageHandler={updateShortListedImages}
              shortListedImages={shortListedImages}
            />
          </div>
        </Tab>
        {userEmail === "saptarshi.misra@gmail.com" || userEmail === "minkuupadhaya@gmail.com" ? (
          <Tab
            eventKey="album"
            title={`${shortListedImages.length} Shortlisted`}
          >
            <div>
              <button
                className="float-{sm,md,lg,xl}-right"
                onClick={refreshImages}
              >
                Refresh{" "}
              </button>
              <ShortList
                images={shortListedImages}
                removeShortListed={removeShortListedImage}
              />
            </div>
          </Tab>
        ) : (
          ""
        )}
        {/* <Tab eventKey="contact" title="Contact" disabled>
          <Sonnet />
        </Tab> */}
      </Tabs>
    );
  }

  return (
    <div className="Home">
      {isAuthenticated ? ControlledTabs() : renderLander()}
    </div>
  );
}
