import React from 'react';
import { Storage } from 'aws-amplify'
import { S3Image } from 'aws-amplify-react';
import { motion } from 'framer-motion';
import { Button, Image } from 'react-bootstrap';
import { Auth } from 'aws-amplify'

class Images extends React.Component {

  constructor(props) {
    super(props);
    this.collectImageId = this.collectImageId.bind(this);
    // this.state.files= [this.props.images];
    console.log(props)
  }
  state = {
    // files: [this.props.images],
    files: [],
    shortlistedImages: [],
    shortlist:"shortlist",
    userEmail: ''
  }
  async shouldComponentUpdate() {
    // this.setState({files:this.props.images});
  }
  async componentDidUpdate(previousProps) {
    if (previousProps.images !== this.props.images) {
      let imageKeys = [];
      if (this.props.images) {
        this.props.images.map(each => {
          if(each && each.key) imageKeys.push(each.key);
        });
        this.setState({ files: imageKeys });
      }
    }
  }
  async componentDidMount() {
    // check the render image list
    console.log('found below images\n' + this.state.images);
    setTimeout(async()=>{
      try{
        let session = await Auth.currentSession();
        this.setState({userEmail:session.idToken.payload.email});
        // resolve(accessToken);
        
      }catch(error){
      }
      // resolve()
    },1000);
    
  }

  collectImageId(params) {
    this.setState({shortlist:''});
    this.props.updateShortListedImageHandler({ key: params });
  };

  adminView(){
    return (
          <div >
              <div>
              <div className="row">
                {
                  this.state.files.map((f, i) => (
                    <div className="col-sm-4">
                      <div className = "card" style={{ width: "18rem"}}>
                        {/* <motion.div key={i}
                          layout
                          whileHover={{ opacity: 1 }}

                        > */}
                          <S3Image className ="card-img-top" identityId='ap-south-1:e4f7351e-2de2-48d4-b53c-5e45399a4dac' imgKey={f}
                            alt="uploaded pic"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 1 }}
                          />
                        {/* </motion.div> */}
                      <div className="card-body">
                        {
                        !(
                            this.props.shortListedImages
                            &&
                            (
                                this.props.shortListedImages
                                    .reduce((accumulator, currentValue) => accumulator + currentValue.key
                                     + ',', '')
                                     .split(',')
                                     .includes(f)
                            )
                        )
                         ?
                         <Button className="btn btn-primary"
                                onClick={this.collectImageId.bind(this, f)}>
                                ShortList</Button>

                        : <Button className="btn">Short-Listed</Button>
                                                  }</div>
                    </div>
                  </div>
                  ))
                }
                </div>
              </div>
            </div>
        );
  }

  visitorView(){
      return (
            <div >
                <div>
                <div className="row">
                  {
                    this.state.files.map((f, i) => (
                      <div className="col-sm-4">
                        <div className = "card" style={{ width: "18rem"}}>
                          {/* <motion.div key={i}
                            layout
                            whileHover={{ opacity: 1 }}

                          > */}
                            <S3Image className ="card-img-top" identityId='ap-south-1:e4f7351e-2de2-48d4-b53c-5e45399a4dac' imgKey={f}
                              alt="uploaded pic"
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              transition={{ delay: 1 }}
                            />
                          {/* </motion.div> */}
                        <div className="card-body">
                           <Button className="btn">Image</Button>
                        </div>
                      </div>
                    </div>
                    ))
                  }
                  </div>
                </div>
              </div>
          )
  }


  render() {
      let divElem = '';
      let view = this.state.userEmail === 'saptarshi.misra@gmail.com'
      if (view){
        divElem = this.adminView();
      }else{
        divElem = this.visitorView();
      }
      return (
        <div>
          {divElem}
        </div>
      );
  }
}

export default Images;